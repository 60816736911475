<template>
  <v-row class="messages-item">
    <v-col cols="12" class="pa-0">
      <div class="title">
        <div class="text-sm-h5 text-h6 font-weight-bold">
          <router-link to="/messages" class="white--text">
            <v-icon class="white--text text-sm-h4 text-h5 mb-1">mdi-chevron-left</v-icon>Message {{ data.id }}
          </router-link>
        </div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <div v-else>
        <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5">
          <div class="d-flex flex-wrap justify-space-between mb-sm-0 mb-3">
            <div class="text-uppercase text-h5 font-weight-bold">General</div>
            <div class="f18 status" :class="getColorClass(data.status)">
              {{ data.status }}
            </div>
          </div>
          <div class="px-sm-10 py-sm-5">
            <v-row>
              <v-col cols="12" md="6">
                <div class="font-weight-medium">Message ID</div>
                <div class="opasity--text">{{ data.id }}</div>
                <div class="font-weight-medium mt-3">Source address</div>
                <div class="opasity--text">{{ data.from }}</div>
                <div class="font-weight-medium mt-3">Destination address</div>
                <div class="opasity--text">{{ data.to }}</div>
                <div class="font-weight-medium mt-3">Origin</div>
                <router-link v-if="data.campaign_name" class="input--text" :to="`/campaigns/${data.campaign_id}`">
                  {{ data.campaign_name }}
                </router-link>
                <div v-else class="opasity--text">{{ data.origin.title }}</div>
                <div class="font-weight-medium mt-3">Segments</div>
                <div class="opasity--text">{{ data.segments }}</div>
                <div class="font-weight-medium mt-3">Submitted at</div>
                <div class="opasity--text">{{ new Date(data.submitted_at).toLocaleString() }}</div>
              </v-col>
              <v-col cols="12" md="6">
                <div class="font-weight-medium">Country/Operator</div>
                <div class="opasity--text">{{ data.country }}/{{ data.operator }}</div>
                <div class="font-weight-medium mt-3">MCC/MNC</div>
                <div class="opasity--text">{{ data.mcc }}/{{ data.mnc }}</div>
                <div class="font-weight-medium mt-3">Encoding</div>
                <div class="opasity--text">{{ data.encoding }}</div>
                <div class="font-weight-medium mt-3">Cost</div>
                <div class="opasity--text">{{ data.cost }}</div>
                <div class="font-weight-medium mt-3">Access type</div>
                <div class="opasity--text">{{ data.access_type.title }}</div>
              </v-col>
            </v-row>
            <div class="mt-5 text-h6">Message Preview:</div>
            <div class="f18 opasity--text">{{ data.body }}</div>
          </div>
        </v-card>
        <v-card v-if="data.origin.name == 'rest'" elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5 mt-5">
          <div class="text-uppercase text-h5 font-weight-bold">Rest API</div>
          <div class="px-sm-10 py-sm-5">
            <v-row>
              <v-col cols="12" md="6">
                <div class="font-weight-medium">Source IP address</div>
                <div class="opasity--text">{{ data.source_address }}</div>
                <div class="font-weight-medium mt-3">API endpoint</div>
                <div class="opasity--text">{{ data.api_endpoint }}</div>
              </v-col>
              <v-col cols="12" md="6">
                <div class="font-weight-medium">Access type</div>
                <div class="opasity--text">{{ data.access_type.title }}</div>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5 mt-5">
          <div class="text-uppercase text-h5 font-weight-bold">Segments</div>
          <div class="px-sm-10 pb-sm-5">
            <v-card class="mt-5" tile outlined v-for="(item, i) in data.segments_list" :key="i">
              <div class="d-flex flex-wrap justify-space-between align-center py-3 px-5">
                <div class="text-uppercase text-h6">Segment {{ i + 1 }}</div>
                <div class="status-small" :class="getColorClass(item.status)">{{ item.status }}</div>
              </div>
              <div v-for="segment in segmentItems" :key="segment.name">
                <v-divider></v-divider>
                <div class="d-flex">
                  <div class="opasity--text font-weight-medium px-5 py-3 segment-name">{{ segment.name }}</div>
                  <v-divider vertical></v-divider>
                  <div class="font-weight-medium px-5 py-3">
                    {{
                      Number.isInteger(item[segment.value]) ? new Date(item[segment.value]).toLocaleString() : item[segment.value] || '-'
                    }}
                  </div>
                </div>
              </div>
            </v-card>
          </div>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      load: true,
      segmentItems: [
        { name: 'Submitted at', value: 'submitted_at' },
        { name: 'Sent at', value: 'sent_at' },
        { name: 'Delivered at', value: 'delivered_at' },
        { name: 'Sent error', value: 'send_error' },
        { name: 'SMPP session', value: 'smpp_session' },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getColorClass(block) {
      if (block == 'Scheduled') {
        return 'cyan white--text';
      } else if (block == 'Sent') {
        return 'success white--text';
      } else if (block == 'Delivered') {
        return 'input white--text';
      } else if (block == 'Filtered') {
        return 'red darken-1 white--text';
      } else if (block == 'Payment failed') {
        return 'gray white--text';
      } else if (block == 'Send failed') {
        return 'error white--text';
      } else if (block == 'Undelivered') {
        return 'white opasity--text';
      }
    },
    notifi(btn) {
      if (btn == 'search400') {
        this.$notify({ message: 'Error', type: 'error', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == '500') {
        this.$notify({
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async getData() {
      await this.$store.dispatch('getMessages').finally(() => {
        this.load = false;
      });
    },
  },
  computed: {
    data() {
      return this.$store.getters.messages;
    },
  },
  destroyed() {
    this.$store.dispatch('setMessages', { access_type: {}, origin: {} });
  },
};
</script>

<style lang="scss">
.messages-item {
  .status {
    line-height: 34px;
    padding: 0 20px;
    width: max-content;
    border-radius: 24px;
  }
  .status-small {
    font-size: 14px;
    line-height: 24px;
    padding: 0 16px;
    width: max-content;
    border-radius: 24px;
  }
  .segment-name {
    max-width: 220px;
    width: 100%;
  }
}
</style>
